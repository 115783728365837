exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-draw-next-pls-js": () => import("./../../../src/pages/draw_next_pls.js" /* webpackChunkName: "component---src-pages-draw-next-pls-js" */),
  "component---src-pages-enter-score-2-js": () => import("./../../../src/pages/enter_score2.js" /* webpackChunkName: "component---src-pages-enter-score-2-js" */),
  "component---src-pages-enter-score-3-js": () => import("./../../../src/pages/enter_score3.js" /* webpackChunkName: "component---src-pages-enter-score-3-js" */),
  "component---src-pages-enter-score-js": () => import("./../../../src/pages/enter_score.js" /* webpackChunkName: "component---src-pages-enter-score-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internal-2023-js": () => import("./../../../src/pages/internal_2023.js" /* webpackChunkName: "component---src-pages-internal-2023-js" */),
  "component---src-pages-internal-competition-2-js": () => import("./../../../src/pages/internal_competition2.js" /* webpackChunkName: "component---src-pages-internal-competition-2-js" */),
  "component---src-pages-internal-competition-3-js": () => import("./../../../src/pages/internal_competition3.js" /* webpackChunkName: "component---src-pages-internal-competition-3-js" */),
  "component---src-pages-internal-competition-js": () => import("./../../../src/pages/internal_competition.js" /* webpackChunkName: "component---src-pages-internal-competition-js" */),
  "component---src-pages-order-of-play-js": () => import("./../../../src/pages/order_of_play.js" /* webpackChunkName: "component---src-pages-order-of-play-js" */),
  "component---src-pages-score-2023-index-js": () => import("./../../../src/pages/score_2023/index.js" /* webpackChunkName: "component---src-pages-score-2023-index-js" */)
}

